<template>
  <div class="news" v-if="item!=null">
    <headerother titleurl="homepage" ref="header"></headerother>
    <div class="bread-crumb">
      <a href="/news/newslist" target="_blank"  clstag="bstxt|title_a">快报</a><span class="bread-crumb--arrow">&gt;</span>
      <a href="" target="_blank" clstag=""
        style="color: rgb(51, 51, 51);">{{item.title}}</a>
    </div>
    <div class="news-bgc">
      <div class="announce-detail--wrapper_content" clstag="bstxt|main_a">
        <div>
          <div class="announce-detail--wrapper_content__title">{{item.title}}</div>
          <div class="announce-detail--wrapper_content__main contentbox" v-html="item.newContent">
            
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { sysnewsgetnewdetail } from '@/api/system';
import headerother from '../../components/headerother';
import Footer from '../../components/footer';
import Cookies from 'js-cookie';
export default {
  name: 'homepage',
  components: {
    headerother,
    Footer,
  },
  data() {
    return {
      id: 0,
      item: null,
    };
  },
  methods: {
    onsysnewsgetnewdetail() {
      var that = this;
      sysnewsgetnewdetail({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 1) {
          that.item = res.data.result;
        }
      });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.onsysnewsgetnewdetail();
  },
};
</script>
<style scoped>
.news .news-bgc {
  /* width: 100%; */
  margin: 0 auto;
  background: #fff;
  width: 1140px;
  min-height: 560px;
  padding: 10px 30px;
}
.bread-crumb {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  width: 1200px;
  margin: 0 auto;
}
.bread-crumb a,
.bread-crumb span {
  overflow: hidden;
  display: inline-block;
}
.bread-crumb a {
  cursor: pointer;
  text-decoration: none;
  color: #999;
  max-width: 600px;
  font-size: 15px;
}
.bread-crumb--arrow {
  font-family: simsun;
  font-size: 19px;
  font-weight: 300;
  margin: 0 10px;
  color: #999;
}
.bread-crumb a,
.bread-crumb span {
  overflow: hidden;
  display: inline-block;
}
.announce-detail--wrapper_content {
  background-color: #fff;
  padding-left: 100px;
  padding-bottom: 70px;
}
.announce-detail--wrapper_content__title {
  padding-top: 70px;
  padding-bottom: 20px;
  font-size: 20px;
  color: #333;
  font-weight: 600;
  line-height: 1;
}
.announce-detail--wrapper_content__main {
  font-size: 14px;
  padding-right: 100px;
  color: #666;
  line-height: 20px;
  margin-top: 40px;
}
.announce-detail--wrapper_content__main p {
  margin-bottom: 30px !important;
  word-wrap: break-word !important;
  line-height: 26px;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  width: 1000px;
  white-space: pre-wrap !important;
}
</style>